import React from 'react'
import loadingImg from "@/assets/images/loading.gif";
import './index.less'
export default function LoadingCom (props) {
    const {loading} = props
    return (
        (
            loading && (
                <div className="loading-com flex-ac">
                    <img src={loadingImg} alt="loading" width="40" />
                </div>
            )
        ) || null
    );
}