import {Toast} from '@nutui/nutui-react';
import axios from "axios";
import {baseURL} from "./apiUrl";
import path from "./apiPath";
import {dictsStore, globalStore, userStore} from "../store";
import {getQueryString, replaceMatch} from "@/utils/tool";

let needMsg = true;

// 创建axios实例
const service = axios.create({});

service.interceptors.response.use(
    (response) => {
        const data = response.data;
        if (response.status === 200 || response.status === 201) {
            if (data.ret) {
                return response;
            } else {
                const code = data.code < 10000;
                // needMsg !== false && code && Toast.fail(data.msg || "System error", 3, null, false);
                return response;
            }
        }
        return response;
    },
    (error) => Promise.reject(error)
);

const request = (config) => {
    const {
        host,
        headers = {},
        method = "get",
        loading,
        api,
        match,
        params = {},
        data = {},
        callback,
        errorback,
        contentType,
        downLoad,
        msg,
        successmsg,
        errorToLogin,
    } = config;
    const apiVersion = "v1";
    const isGet = method === "get";
    const {search} = window.location;
    needMsg = msg;
    if (api && path.hasOwnProperty(api)) {
        let url = `${host ? `${host}` : `${baseURL}/admin`}${path[api]}`;

        if (match) {
            url = replaceMatch(url, match);
        }

        if (loading) {
            globalStore.loading = loading;
        }

        const ack = (isGet && `${new Date().getTime()}`) || "";
        const utm_medium = getQueryString(search, "utm_medium");
        const utm_campaign = getQueryString(search, "utm_campaign");
        const utm_source = getQueryString(search, "utm_source");
        const utm_content = getQueryString(search, "utm_content");
        const utm_term = getQueryString(search, "utm_term");
        return service({
            method: method,
            url: url,
            params: {...params, ...(isGet && {ack})},
            headers: {
                ...headers,
                ...((isGet || contentType) && {"Content-Type": contentType || "application/x-www-form-urlencoded; charset=utf-8"}),
                "Accept-Language": globalStore.language,
                "Web-Version": `h5@${dictsStore.version}`,
                ...(utm_medium && {"X-UTM-Medium": utm_medium}),
                ...(utm_campaign && {"X-UTM-Campaign": utm_campaign}),
                ...(utm_source && {"X-UTM-Source": utm_source}),
                ...(utm_content && {"X-UTM-Content": utm_content}),
                ...(utm_term && {"X-UTM-Term": utm_term}),
            },
            withCredentials: true,
            // processData: false,
            data: contentType ? data : {...data},
        })
            .then((res) => {
                const isSuccess = res.data.ret;
                const data = downLoad ? res : (isSuccess && res.data.data) || res.data;
                // isSuccess && successmsg && Toast.success(<div className="pre">{t(["msg", successmsg])}</div>, 3, null, false);
                globalStore.loading = false;
                if (downLoad || !ack || (ack && ack === res.data.meta.ack)) {
                    return callback(data, res.data.meta, isSuccess);
                }
            })
            .catch((error) => {
                globalStore.loading = false;
                const status = error?.response?.status;
                if (status === 401) {
                    // 返回 401 清除token信息并跳转到登录页面
                    userStore.logOut();
                } else if (status === 403) {
                    // You do not have permission to access
                    // globalStore.navigate("/*");
                    Toast.show({content: "403，系统信息错误",icon: 'fail'})
                } else if (status === 404) {
                    // globalStore.navigate("/*");
                    Toast.show({content: "404，系统信息错误",icon: 'fail'})
                }
                return errorback && errorback(error);
            });
    }
};

export default request;
