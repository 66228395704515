const pulic = {
    getUserInfo: "/staff", // 获取用户信息
    getMeInfo: "/staff/me", // 获取登录信息
    postLogout: "/staff/sign_out", // 退出登录
    getDicts: "/dimensions", // 字典值
    getUserList: "/staff/staff_list", // 用户列表
    postQySign: "/staff/qywechat_sign",//获取签名
    getDocument: "/oss/get_download_url", // 文件的下载
};
const experts = {
    getExpertIndustries: "/experts/industries", //专家研究的行业
    postExpertList: "/experts/search", //专家库列表
    expertContacts: "/experts/:id/contacts", // 获取/修改 专家联系方式
    getExpertDetail: "/experts/:id", //专家详情
    getExpertFollowRecord: "/experts/:id/follow_ups", //获取跟进记录
    getExpertResumeList: "/experts/:id/resumes", // 专家经历列表
    getExperts: "/experts", //专家库下拉列表
};
const expertDemands = {
    getExpertDemandList: "/expert_demands", // 获取需求列表
    postExpertDemand: "/expert_demands", // 创建需求
    putExpertDemand: "/expert_demands/:id", // 更新需求
    delExpertDemand: "/expert_demands/:id", // 删除需求
    getExpertDemandInfo: "/expert_demands/:id", // 获取需求详情
    getExpertDemandExpertList: "/expert_demands/:id/experts", // 获取专家列表/专家历史列表
    getExpertDemandExpertRemarkList: "/expert_demands/:id/experts/:expert_demand_expert_id/remarks", // 获取备注
    postExpertDemandsRecognition: "/expert_demands/recognition",     //根据粘贴内容识别需求内容
    getExpertDemandAnalyze: "/expert_demands/:id/analyze",  //获取需求的AI解析
    expertDemandGroup: "/expert_demands/:id/expert_groups", // 获取/创建 分组
    postExpertDemandExpert: "/expert_demands/:id/experts", // 添加专家
};
const organization = {
    getSelectCor: "/sators/corporations", // 公司下拉列表
    getOrgList: "/organizations", // 获取组织列表/下拉
    getOrganizationUsers: "/organization_users", // 获取当前组织的成员
}

const wechatMessage = {
    getWechatMessages: "/wechat_messages",                      //微信消息列表
    getWechatSearchContact: "/wechat_messages/search_contact",   //通过自己最近的发言搜索微信群组或者私聊
    getWechatContact: "/wechat_messages/get_contact",       //获取微信群组或者私聊的用户信息
    postWechatContact: "/wechat_messages/set_contact",       //绑定微信群组或者私聊信息
}



const All = {
    ...pulic,
    ...experts,
    ...expertDemands,
    ...organization,
    ...wechatMessage,
};

export default All;
