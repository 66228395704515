const zhCN = {
    chinese: "中文",
    english: "英文",
    pageLoading: "玩命加载中...",
    loaded: "已全部加载",
    loadMore: "上拉加载更多",
    searchPlace: "请输入关键词搜索",
    inputNumber: "请输入数字",
    release: "松开立即刷新",
    releaseFinish: "刷新完成",
    releaseLoad: "松开加载更多",
    releaseLoadFinish: "加载完成",
    inputPlace: "请输入",
    selectPlace: "请选择",
    formRequired: "是必填项",
    optional: "非必填",
    empty: "暂无数据",
    reset: "重置",
    giveUp: "放弃",
    confirm: "确定",
    cancel: "取消",
    close: "关闭",
    gotIt: "知道了",
    exit: "退出",
    pageStatusText: "页面不存在",
    contactInfo: "联系方式",
    backHome: "返回首页",
    nav: {
        signIn: "登录",
        expertDatabase: "专家库",
        demand: "需求",
        newDemand: "新增需求",
        expertDetail: "专家详情",
    },
    expert: {
        detail: "专家详情"
    },
    demand: {
        add: "新增需求",
        detail: "需求详情",
        pastetext: "文本粘贴",
        aiAnalysis: "AI 推荐",
    },
    status: {
        registering: "报名中",
        registered: "已报名",
        playback: "回放",
        finished: "已结束",
    },
    search: {
        searchKeyword: "请输入关键词/公司名/股票代码/行业…",
        pastRecords: "历史搜索",
        hostSearch: "本营热搜榜",
        hotTopics: "热门讨论",
        relatedTopics: "相关讨论",
        relatedOwner: "相关营主",
        name: "搜索",
        corporation: "公司",
    },
    btn: {
        expand: "展开",
        close: "收起",
        recognize: "识别",
        pasteAndRecognize: "粘贴并识别",
    },
};

export default zhCN;
