import zh from "@/i18n/zh";
import en from "@/i18n/en";
import { deepGet, isZh } from "./utils/tool";

export const t = (name, params) => {
        const lang = isZh() ? zh : en;
        const content = deepGet(lang, name, params);

        if (!content) {
            console.error(name, `${lang}: ${name} has no match`);
            return null;
        }
        return content;
    },
    jointStr = (arr,notChange) => {
        const content = arr?.map((item) => t(item));
        const str = content.reduce((o, k) => `${o}${isZh() ? "" : " "}${notChange ? k : k?.toLowerCase()}`);
        return str;
    };
