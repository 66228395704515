import { Component } from 'react'
import { t } from '@/i18n';
import { Button } from '@nutui/nutui-react'
import {handleBackHome} from "@/utils/app";
import "./index.less";


export default class NotFound extends Component {
    render() {
        return (
            <div className="page-result flex-ac">
                <div style={{textAlign: "center"}}>
                    <img src="https://static.acecamptech.com/system/notfound.svg" className="icon404" alt="404" />
                    <div className="page-status-text">{t("pageStatusText")}</div>
                    <Button type='primary' onClick={() => handleBackHome()}>
                        {t("backHome")}
                    </Button>
                </div>
            </div>
        );
    }
}
