import { t } from "@/i18n";
import request from "@/request";
import { delSession } from "@/utils/tool";
import { action, computed, observable } from "mobx";
import moment from "moment";

class DictsStore {
    @observable getDictsData = {};
    @observable getRegionsData = [];
    @observable version = "1.2.3";
    @observable ai_version = "1.1.0";
    @observable disabledIds = [];
    @action getDicts() {
        const dictKey = `dicts${this.version}`;
        delSession("dicts", dictKey);
        const sessionDict = sessionStorage.getItem(dictKey);
        if (!sessionDict || sessionDict === "{}") {
            return request({
                api: "getDicts",
                callback: (res) => {
                    const data = (res.code && {}) || res;
                    this.getDictsData = data;
                    sessionStorage.setItem(dictKey, JSON.stringify(data));
                },
            });
        } else {
            return (this.getDictsData = JSON.parse(sessionDict) || {});
        }
    }
    // 暂时没有用到
    @action getRegions() {
        const regionsKey = `regions${this.version}`;
        delSession("regions", regionsKey);
        if (!sessionStorage.getItem(regionsKey)) {
            return request({
                api: "getRegions",
                params: {
                    deep_size: 3,
                },
                callback: (res) => {
                    const data = (res.code && []) || res;
                    this.getRegionsData = data;
                    sessionStorage.setItem(regionsKey, JSON.stringify(data));
                },
            });
        } else {
            this.getRegionsData = JSON.parse(sessionStorage.getItem(regionsKey)) || [];
        }
    }
    // 获取某个地区的信息
    @action getRegionInfo(id) {
        if (id) {
            return request({
                api: "getRegionInfo",
                match: {id},
                params: {
                    deep_size: 1,
                },
                callback: (res) => {
                    const data = !res.code && res?.length > 0 ? res[0] : this.dicts.default_country_code;
                    return data;
                },
            });
        }
    }
    @action getMonth() {
        const dataArr = [];
        for (var i = -6; i < 2; i++) {
            let obj = {};
            const value = moment().add(i, "month");
            const timeStar = moment(value).startOf("month").valueOf() / 1000;
            const timeEnd = moment(value).endOf("month").valueOf() / 1000;
            obj = {
                id: `${timeStar},${timeEnd}`,
                sc_name: moment(value).format("YYYY/MM"),
                en_name: moment(value).format("YYYY/MM"),
            };
            dataArr.push(obj);
        }
        return dataArr;
    }
    @action getOrganizationTypesGroup() {
        const organization_types = this.getDictsData.organization_types || [];
        const data = organization_types.filter((item) => this.disabledIds?.indexOf(item.id) === -1);
        return data;
    }
    // @ts-ignore
    @computed get dicts() {
        return {
            ...this.getDictsData,
            yes_or_no: [
                {id: true, sc_name: "是", en_name: "Yes"},
                {id: false, sc_name: "否", en_name: "No"},
            ],
            expert_types: [
                {id: "employee", sc_name: "A类 上市公司现有员工", en_name: "", type: "A类", letter: "A"},
                {id: "ex-employee",
                    sc_name: "B类 上市公司离职员工（或上下游跟上市公司关系特别紧密的公司，直接竞争公司，大区销售经理等间接了解上市公司现状的专家）",
                    en_name: "",type: "B类", letter: "B"},
                {id: "expert", sc_name: "C类 纯行业专家（行业协会，政策顾问等）", en_name: "", type: "C类", letter: "C"},
                {id: "government", sc_name: "G类 政府机构现任官员", en_name: "", type: "G类", letter: "G"},
            ],
            currency: [
                {id: "CNY", sc_name: "人民币", symbol: "¥"},
                {id: "HKD", sc_name: "港币", symbol: "HK$"},
                {id: "USD", sc_name: "美元", symbol: "$"},
            ],
            //专家价格税前or税后
            expert_price_tex: [
                {id: "before", sc_name: "税前"},
                {id: "after", sc_name: "税后"},
            ],
            contact_types: [
                {id: "phone", sc_name: "手机号", en_name: "Mobile Number"},
                {id: "email", sc_name: "邮箱", en_name: "Email"},
                {id: "wechat", sc_name: "微信", en_name: "WeChat"},
                {id: "tel", sc_name: "固话", en_name: "Tel"},
            ],
            live_score_types: [
                {
                    id: "positive",
                    sc_name: "满意",
                    en_name: "Satisfactory",
                    icon: "iconsmile",
                    expert: [
                        {id: "1", sc_name: "专业", en_name: "Professional"},
                        {id: "2", sc_name: "数据可信度高", en_name: "Reliable data"},
                        {id: "3", sc_name: "经验丰富", en_name: "Experienced"},
                        {id: "4", sc_name: "分享意愿高", en_name: "Willing to share"},
                        {id: "other", sc_name: "其他", en_name: "Others"},
                    ],
                    analysis: [
                        {id: "1", sc_name: "专业", en_name: "Professional"},
                        {id: "2", sc_name: "数据可信度高", en_name: "Reliable data"},
                        {id: "3", sc_name: "经验丰富", en_name: "Experienced"},
                        {id: "4", sc_name: "分享意愿高", en_name: "Willing to share"},
                        {id: "other", sc_name: "其他", en_name: "Others"},
                    ],
                    topic: [
                        {id: "1", sc_name: "深度挖掘", en_name: "Depth"},
                        {id: "2", sc_name: "多角度切入", en_name: "Multiple perspectives"},
                        {id: "3", sc_name: "问题清晰", en_name: "Clarity issue"},
                        {id: "4", sc_name: "市场关注度高", en_name: "High market concern"},
                        {id: "other", sc_name: "其他", en_name: "Others"},
                    ],
                },
                {id: "neutral", sc_name: "一般", en_name: "Average", icon: "iconcalm"},
                {
                    id: "negative",
                    sc_name: "不满意",
                    en_name: "Dissatisfied",
                    icon: "iconsad",
                    expert: [
                        {id: "1", sc_name: "不专业", en_name: "Unprofessional"},
                        {id: "2", sc_name: "数据存疑", en_name: "Doubtful data"},
                        {id: "3", sc_name: "话题匹配度低", en_name: "Topic mismatched"},
                        {id: "4", sc_name: "表达能力差", en_name: "Weak in expression"},
                        {id: "5", sc_name: "分享意愿低", en_name: "Unwilling to share"},
                        {id: "other", sc_name: "其他", en_name: "Others"},
                    ],
                    analysis: [
                        {id: "1", sc_name: "不专业", en_name: "Unprofessional"},
                        {id: "2", sc_name: "数据存疑", en_name: "Doubtful data"},
                        {id: "3", sc_name: "表达能力差", en_name: "Weak in expression"},
                        {id: "4", sc_name: "分享意愿低", en_name: "Unwilling to share"},
                        {id: "5", sc_name: "态度恶劣", en_name: "Bad attitude"},
                        {id: "other", sc_name: "其他", en_name: "Others"},
                    ],
                    topic: [
                        {id: "1", sc_name: "深度不够", en_name: "No depth"},
                        {id: "2", sc_name: "内容单一", en_name: "Not informative"},
                        {id: "3", sc_name: "问题模糊", en_name: "Fuzzy issue"},
                        {id: "4", sc_name: "市场关注度低", en_name: "Low market concern"},
                        {id: "other", sc_name: "其他", en_name: "Others"},
                    ],
                },
            ],
            expert_file_types: [
                {id: "agreement", sc_name: "本营专家协议"},
                {id: "compliance", sc_name: "专家合规培训确认函"},
                {id: "resume", sc_name: "专家背景核查表"},
                {id: "other", sc_name: "其他"},
            ],
            expert_demand_status: [
                {id: "draft", sc_name: "草稿", class: "status-btn-gray"},
                {id: "ongoing", sc_name: "进行中", class: "status-btn-orange"},
                {id: "finished", sc_name: "已完成", class: "status-btn-green"}, // 关闭
                {id: "shelved", sc_name: "已搁置", class: "status-btn-gray"},
                {id: "pending", sc_name: "合规审核中", class: "status-btn-orange"},
                {id: "rejected", sc_name: "合规审核驳回", class: "status-btn-red"},
            ],
            meeting_types: [
                {id: "public", sc_name: "Group"},
                {id: "tegus", sc_name: "1V1 Tegus"},
                {id: "private", sc_name: "1V1 传统"},
            ],
            expert_demand_meeting_mode: [
                {id: "online", sc_name: "线上直播", simple_sc_name: "线上"},
                {id: "offline", sc_name: "线下会议", simple_sc_name: "线下"},
            ],
            expert_sign_status: [
                {id: true, sc_name: "已签约"},
                {id: false, sc_name: "未签约"},
            ],
            demand_priority: [
                {id: "10", sc_name: "高", tag_class_name: "status-red-tag"},
                {id: "20", sc_name: "中", tag_class_name: "status-yellow-tag"},
                {id: "30", sc_name: "普通",}
            ],
            //企业微信-进入页面的场景类型
            //1. getCurExternalContact-获取当前外部联系人 userId
            //2. getCurExternalChat-当前客户群的群 ID
            //3. getCurCorpGroupContact-获取当前上下游联系人用户 ID
            //4. getCurCorpGroupChat-获取当前上下游互联群的群 ID
            ww_entry_map: [
                {id: "contact_profile", api:"getCurExternalContact", type: "user"},    //从联系人详情进入
                {id: "single_chat_tools", api:"getCurExternalContact", type: "user"},  //从单聊会话的工具栏进入
                {id: "group_chat_tools", api:"getCurExternalChat", type: "room"},   //从群聊会话的工具栏进入
                {id: "chat_attachment", api:"getCurExternalContact", type: "user"},    //从会话的聊天附件栏进入（应用-可能是群聊/个人）
                {id: "single_kf_tools", api:"getCurExternalContact", type: "user"},    //从微信客服的工具栏进入
                {id: "chain_single_chat_tools", api:"getCurCorpGroupContact", type: "user"},    //上下游单聊会话的工具栏
                {id: "chain_group_chat_tools", api:"getCurCorpGroupChat", type: "room"}, //上下游群聊会话的工具栏
                {id: "normal", api:"", type: "other"},             //除以上场景之外进入，例如工作台，聊天会话等
            ],
            wechat_type: [
                {id: 'text', show_content: true, name: "文本",},
                {id: 'link', show_content: true, name: "链接", },
                {id: 'image', show_content: true, name: "图片", },
                {id: 'video', show_content: true, name: "视频", },
                {id: 'card', show_content: false, name: "名片", },
                {id: 'external_redpacket', show_content: false, name: "红包", },   //红包
                {id: 'location', show_content: false, name: "位置", },
                {id: 'file', show_content: true, name: "文件", },
                {id: 'chatrecord', show_content: true, name: "聊天记录", },
            ]
        };
    }
}

const dictsStore = new DictsStore()

export default dictsStore;
