import request from "@/request";
import { action, computed, observable } from "mobx";
import { globalStore } from "../index";
import {agentId, corpId, HOST} from "@/request/apiUrl";
import {getDictsStr} from "@/utils/tool";
import * as ww from '@wecom/jssdk';

class UserStore {
    @observable loading = false;
    @observable userInfo = {};
    @observable code = null;
    @observable redirect = null;
    @action async getUserInfo(isLogin) {
        this.loading = true;
        return request({
            api: "getUserInfo",
            params: {
                code: this.code,
                state: 'm'
            },
            callback: async (res) => {
                this.loading = false;
                console.log('getUserInfo', res);
                if (!res.code) {
                    this.userInfo = res || {};
                    this.toLink(isLogin);
                    // await this.getWwInfo();
                } else {
                    this.userInfo = {};
                }
                return this.userInfo;
            },
        });
    }

    @action toLink = (isLogin) => {
        if (this.redirect && this.redirect !== "null" && this.redirect !== "undefined") {
            console.log('toLink-redirect', this.redirect);
            window.location.href = this.redirect;
        } else if (isLogin) {
            globalStore.navigate("/search")
        }
    };
    @action logOut = () => {
        this.userInfo = {};
        this.loading = false;
        if (window.location.pathname.indexOf("/login") === -1) {
            window.location.replace("/login");
        }
    }


    @action getSign = async (source) => {
        return request({
            api: "postQySign",
            method: "post",
            params: {url: window.location.href, source},
            loading: true,
            callback: (res, meta) => {
                console.log('res', res);
                if(!res.code){
                    return res;
                }else{
                    return {};
                }
            },
        });
    }
    //根据ticket生成jsapi签名
    //获取企业微信外部联系人/群聊信息
    @action getWwInfo = async () => {
        console.log("开始获取wwinfo");
        //获取签名
        const getSign = async (source) => {
            return request({
                api: "postQySign",
                method: "post",
                params: {url: window.location.href, source},
                loading: true,
                callback: (res, meta) => {
                    if(!res.code){
                        return res;
                    }else{
                        return {};
                    }
                },
            });
        }
        // @ts-ignore
        // const ww = window.ww;
        ww.register({
            corpId: corpId, // 必填，当前用户企业所属企业ID
            agentId: agentId, //当前第三方应用 AgentID
            // suiteId: suiteId, // 应用套件 ID
            jsApiList: [
                'getContext',
                'getCurExternalContact',
                'getCurExternalChat',
                'getCurCorpGroupContact',
                'getCurCorpGroupChat',
            ],// 必填，需要使用的JSAPI列表
            //签名生成函数-企业jsapi_ticke
            async getConfigSignature() {
                const sign = await getSign('m');
                return sign;
            },
            async getAgentConfigSignature() {
                const agentSign = await getSign('m-agent');
                return agentSign;
            },
        })

        //获取入口参数entry
        await ww.getContext({
            fail(res) {
                console.log('getContext fail', res);
            },
            //成功回调
            async success(result) {
                console.log('getContext-success-result', result);
                const {entry, errCode, errMsg} = result;
                //根据entry匹配获取id的api
                const getIdApi = getDictsStr("ww_entry_map", entry, {labelKey: "api"});
                console.log('getIdApi', getIdApi);
                if(getIdApi){
                    await ww[getIdApi]({
                         success: (result) => {
                            const {chatId, userId} = result;
                            globalStore.qyWechatInfo = {qywechat_chat_type: entry || "", qywechat_chat_id: chatId || userId || null}
                            console.log('获取企业微信会话result', result);

                        },
                        fail: (res) => {
                            console.log('fail-res', res);
                        },
                    })
                }else{
                    console.log('no-getIdApi');
                }
            },
        })
    }

    // 是否为本地开发
    // @ts-ignore
    @computed get isLocalDev() {
        return !HOST && window.location.protocol === "http:";
    }
}

const userStore = new UserStore();
export default userStore;
