import { Suspense, lazy } from "react";
import { Route, Routes, Navigate, useRoutes} from "react-router-dom";
import {routers} from "./menuConfig";
import LoadingCom from "@/components/Loading";
import NotFound from "@/pages/notFound";

// const NotFound = import("@/pages/notFound");
const renderLoading = <LoadingCom loading={true}/>;

// 封装一层 专门负责显示页面标题
const DomTitle = (props) => {
    const {router} = props;
    document.title = router.title;
    return <router.component />;
};
/**
 * routers
 */
const renderRouters = (routers) => {
    return routers?.map((router) => {
        if(router.children){
            return router.component ? (
                <Route key={router.path} path={router.path} element={<router.component />}>
                    {router.redirect && <Route path={router.path} element={<Navigate to={router.redirect} />} />}
                    {renderRouters(router.children)}
                    <Route path="*" element={<NotFound />} />
                </Route>
            ) : (
                renderRouters(router.children)
            );
        }
        return <Route key={router.path} path={router.path} element={<DomTitle router={router} />}/>;
    });
}

const Routers = () => {
    return (
        <Suspense fallback={renderLoading}>
            <Routes>
                {renderRouters(routers())}
            </Routes>
        </Suspense>
    )
}

export default Routers;