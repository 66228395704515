import { globalStore } from "@/store";
import { getQueryString, isWechatBrowser } from "./tool";

export const  // 是否在app平台
    isAppPlatform = (search) => {
        const platform = search && getQueryString(search, "platform");
        const hasPlatform = platform && (platform === "android" || platform === "iOS");
        hasPlatform && localStorage.setItem("platform", platform);
        const isApp = isWechatBrowser() ? null : hasPlatform ? platform : localStorage.getItem("platform");
        if (isApp) {
            if (isApp === "iOS") {
                var script = document.createElement("meta");
                script.name = "viewport";
                script.content = "width=device-width, user-scalable=no";
                document.getElementsByTagName("head")[0].appendChild(script);
            }
            document.getElementById("root").style.setProperty("--header_height", "0px");
        }
        return isApp;
    },
    // 返回首页
    handleBackHome = (url, jumpType) => {
        globalStore.navigate('/')
    }
    ;